/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

import Select from "react-select";

import { DateRangePicker } from "react-date-range";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose, IoEyeSharp } from "react-icons/io5";
import { HiMenuAlt4 } from "react-icons/hi";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteRsvpDetailsById,
  getRsvpDetails,
  postEmailNotification,
} from "service/api";
import { Link, useParams } from "react-router-dom";

import ExcelExport from "../../../components/export/Export";
import moment from "moment";

const filterByStatus = [
  { value: null, label: "All" },
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
  { value: "success", label: "Success" },
];

const sectorOptions = [
  { value: null, label: "All" },
  { value: "Advertising & Social Media", label: "Advertising & Social Media" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Auto Sector", label: "Auto Sector" },
  { value: "Banking and Finance", label: "Banking and Finance" },
  { value: "Chemicals & Petrochemicals", label: "Chemicals & Petrochemicals" },
  { value: "Education", label: "Education" },
  { value: "Electrical", label: "Electrical" },
  { value: "Event Management", label: "Event Management" },
  { value: "FMCG Products", label: "FMCG Products" },
  { value: "Food and Farming", label: "Food and Farming" },
  { value: "Furniture", label: "Furniture" },
  { value: "Gems & Jewellery", label: "Gems & Jewellery" },
  { value: "Mining", label: "Mining" },
  { value: "Handicrafts", label: "Handicrafts" },
  { value: "Healthcare", label: "Healthcare" },
  {
    value: "Hospitality, Travel & Tourism",
    label: "Hospitality, Travel & Tourism",
  },
  { value: "Imports & Exports", label: "Imports & Exports" },
  { value: "IT & Digital Media", label: "IT & Digital Media" },
  { value: "Logistics & Shipping", label: "Logistics & Shipping" },
  { value: "Paper & Stationery", label: "Paper & Stationery" },
  { value: "Plastics", label: "Plastics" },
  { value: "Professional Services", label: "Professional Services" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Renewable Energy", label: "Renewable Energy" },
  { value: "Sports & Recreation", label: "Sports & Recreation" },
  { value: "Telecom", label: "Telecom" },
  { value: "Textile", label: "Textile" },
  { value: "Turnkey Projects", label: "Turnkey Projects" },
  { value: "Waste Management", label: "Waste Management" },
];

const sortByPayment = [
  { value: "desc", label: "Descending" },
  { value: "asc", label: "Ascending" },
];

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const RsvpList = () => {
  const { id } = useParams();
  const { eventId } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);

  const [rsvpList, setRsvpList] = useState([]);

  const [exportData, setExportData] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  const [searchCity, setSearchCity] = useState("");

  const [status, setStatus] = useState(null);
  const [sector, setSector] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const [selectedList, setSelectedList] = useState([]);

  const [updateAllModal, setUpdateAllModal] = useState(false);
  const [updateAllStatus, setUpdateAllStatus] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedRsvp, setSelectedRsvp] = useState(null);

  const [datepicker, setDatepicker] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 6,
        1
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: "selection",
      color: "#ef4444",
    },
  ]);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    console.log("e", e);
    setPage(e);
    getRsvpListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getRsvpListFunction();
  };

  const getRsvpListFunction = () => {
    setLoading(true);
    setRsvpList([]);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: false,
    };

    if (search) {
      params.search = search;
    }

    if (searchCountry) {
      params.country = searchCountry;
    }

    if (searchCity) {
      params.city = searchCity;
    }

    if (status !== null) {
      params.status = status?.value;
    }

    if (sector !== null) {
      params.sectors = sector?.value;
    }

    if (sortOption !== null) {
      params.paymentDate = sortOption?.value;
    }

    if (state?.[0]?.startDate && state?.[0]?.endDate) {
      params.startDate = state?.[0]?.startDate;
      params.endDate = state?.[0]?.endDate;
    }

    getRsvpDetails(eventId, params)
      .then((res) => {
        setRsvpList(res?.data);
        setTotalDocs(res?.data?.length);

        const tempArray = res?.data?.map((item) => {
          // Simplify address and billing address logic
          const getAddress = (addressObj) => {
            if (!addressObj) return "-";
            const { address, city, state, zipCode, country } = addressObj;
            return [address, city, state, zipCode, country]
              .filter(Boolean)
              .join(", ");
          };

          const getPaymentSummary = (summary, currency) => {
            if (!summary) return "-";
            let content = "";
            summary.forEach((item, index) => {
              index === 0
                ? (content += `${item?.type} : ${item.price} ${currency} `)
                : (content += `, ${item?.type} : ${item.price} ${currency} `);
            });
            return content;
          };

          console.log("Item: ", item);

          return {
            "Invoice ID": item?.invoiceId?.invoiceId || "-",
            "First Name": item?.personalDetails?.userId?.firstName || "-",
            "Last Name": item?.personalDetails?.userId?.lastName || "-",
            Email: item?.personalDetails?.userId?.email || "-",
            "Phone Number": item?.personalDetails?.userId?.phoneNumber || "-",
            "Company Name": item?.companyDetails?.name || "-",
            "Company Address":
              `${item?.companyDetails?.address?.address} ${item?.companyDetails?.address?.city} ${item?.companyDetails?.address?.state} ${item?.companyDetails?.address?.country}` ||
              "-",
            "Company sector": Array.isArray(item?.companyDetails?.sectors)
              ? item?.companyDetails?.sectors.join(", ")
              : "-",
            "Areas covered by brand": Array.isArray(
              item?.companyDetails?.areaCoveredByBrand
            )
              ? item?.companyDetails?.areaCoveredByBrand.join(", ")
              : "-",
            "Areas to explore by brand": Array.isArray(
              item?.companyDetails?.areaToExploreByBrand
            )
              ? item?.companyDetails?.areaToExploreByBrand.join(", ")
              : "-",
            "Company Email": item?.companyDetails?.email || "-",
            "Company Phone Number": item?.companyDetails?.phoneNumber || "-",
            "Amount Paid": item?.currency
              ? `${item?.currency} ${item?.amount}`
              : `INR ${item?.amount}`,
            "GST Number": item?.companyDetails?.gstNumber,
            "Payment Breakdown": item?.paymentSummary
              ? getPaymentSummary(item?.paymentSummary, item?.currency)
              : "-",
            Status: item?.paymentStatus || "-",
            Address: getAddress(item?.personalDetails?.address),
            "Billing Name": item?.companyDetails?.billingName || "-",
            "Billing Address": getAddress(
              item?.companyDetails?.billingAddress === undefined ||
                item?.companyDetails?.billingAddress?.address === ""
                ? item?.personalDetails?.address
                : item?.companyDetails?.billingAddress
            ),
            Date: new Date(item?.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }),
            "Date of Payment": new Date(item?.updatedAt).toLocaleDateString(
              "en-gb",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              }
            ),
          };
        });

        console.log("tempArray", tempArray);
        setExportData(tempArray);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteRsvpDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getRsvpListFunction();
      });
  };

  const caseInsensitiveSortForName = (rowA, rowB) => {
    const a = rowA?.firstName?.toLowerCase();
    const b = rowB?.lastName?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForCompany = (rowA, rowB) => {
    const a = rowA?.companyDetails?.name?.toLowerCase();
    const b = rowB?.companyDetails?.name?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForRole = (rowA, rowB) => {
    const a = rowA?.personalDetails?.userId?.role?.name?.toLowerCase();
    const b = rowB?.personalDetails?.userId?.role?.name?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForStatus = (rowA, rowB) => {
    const a = rowA.paymentStatus;
    const b = rowB.paymentStatus;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForDateAndTime = (rowA, rowB) => {
    const a = rowA.createdAt.toLowerCase();
    const b = rowB.createdAt.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const caseInsensitiveSortForInvoiceId = (rowA, rowB) => {
    const a = rowA?.invoiceId?.invoiceId?.toLowerCase();
    const b = rowB?.invoiceId?.invoiceId?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.1,
      selector: (row, index) => (
        <span className="text-sm font-ibm-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Invoice Number",
      grow: 1,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-greyText hover:text-logoYellow">
          {row?.invoiceId?.invoiceId ? row?.invoiceId?.invoiceId : "-"}
        </p>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForInvoiceId,
    },
    {
      name: "Name, Email and Phone",
      grow: 2,
      selector: (row) => (
        <div>
          <p className="text-sm font-ibm-semibold text-black">
            {`${row?.personalDetails?.userId?.firstName} ${row?.personalDetails?.userId?.lastName}`}
          </p>
          <a
            href={`mailto:${row?.personalDetails?.userId?.email}`}
            className="mt-1 block text-sm font-ibm-regular text-greyText hover:text-logoYellow"
          >
            {row?.personalDetails?.userId?.email}
          </a>
          <a
            href={`tel:${row?.personalDetails?.userId?.phoneNumber}`}
            className="mt-1 block text-sm font-ibm-regular text-greyText hover:text-logoYellow"
          >
            {row?.personalDetails?.userId?.phoneNumber}
          </a>
        </div>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForName,
    },

    {
      name: "Company",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {row?.companyDetails?.name}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForCompany,
    },
    {
      name: "Attending As",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-bold text-black capitalize">
          {row?.personalDetails?.userId?.role?.name
            ? row?.personalDetails?.userId?.role?.name
            : "-"}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForRole,
    },
    {
      name: "Status",
      grow: 0,
      selector: (row) => (
        <span
          className={`text-sm font-ibm-bold text-black capitalize ${
            row?.paymentStatus === "success"
              ? "text-green-600"
              : "text-orange-600"
          }`}
        >
          {row?.paymentStatus}
        </span>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForStatus,
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
      sortable: true,
      sortFunction: caseInsensitiveSortForDateAndTime,
    },
    {
      name: "Payment Date",
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {row?.paymentStatus === "success"
            ? new Date(row?.updatedAt).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
              })
            : "-"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <Menu>
          <MenuHandler>
            <Button className="shadow-none hover:shadow-none bg-white rounded-full p-2 border border-authBorder">
              <HiMenuAlt4 className="w-5 h-5 text-black" />
            </Button>
          </MenuHandler>
          <MenuList className="p-2">
            <MenuItem>
              <Link
                to={"/rsvp-details/" + row?._id}
                className="block text-sm font-ibm-regular text-black"
              >
                View details
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to={"/invoice/" + row?._id}
                className="block text-sm font-ibm-regular text-black"
              >
                Download invoice
              </Link>
            </MenuItem>
            <MenuItem
              className="text-sm font-ibm-regular text-black"
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
            >
              Delete data
            </MenuItem>
          </MenuList>

          {/* <div className="flex items-center">
            <Tooltip content="View" className="text-sm font-ibm-semibold">
              <Link to={"/rsvp-details/" + row?._id}>
                <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                  <IoEyeSharp className="w-5 h-5 text-greyText" />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip content="Remove" className="text-sm font-ibm-semibold">
              <Button
                onClick={() => {
                  setDeleteId(row?._id);
                  setDeleteModal(!deleteModal);
                }}
                className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
              >
                <FiTrash className="w-4 h-4 text-greyText" />
              </Button>
            </Tooltip>
          </div> */}
        </Menu>
      ),
    },
  ];

  const addEmailNotificationFormik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: yup.object().shape({
      description: yup.mixed().required("Name is required"),
    }),
    onSubmit: (values) => {
      const emailList = [];
      selectedList?.forEach((element) => {
        emailList.push(element?.email);
      });
      const obj = {
        emailList: emailList,
        body: draftToHtml(convertToRaw(values.description.getCurrentContent())),
      };

      console.log(
        "obj>",
        JSON.parse(
          JSON.stringify(
            draftToHtml(convertToRaw(values.description.getCurrentContent()))
          )
        )
      );
      // const formData = new FormData();

      // formData.append("name",JSON.stringify(emailList));
      // console.log("JSON>", JSON.stringify(draftToHtml(convertToRaw(values.description.getCurrentContent()))))
      // formData.append(
      //   "body",
      //   draftToHtml(convertToRaw(values.description.getCurrentContent()))
      // );
      setLoading(true);
      postEmailNotification(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            addEmailNotificationFormik.resetForm();
            setUpdateAllModal(!updateAllModal);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  const handleChangeSelected = (id) => {
    let tempArray = [];
    if (id.selectedRows?.length > 0) {
      id.selectedRows?.forEach((element) => {
        tempArray.push({
          id: element?._id,
          status: element?.status,
          email: element?.personalDetails?.userId?.email,
        });
      });
    }
    setSelectedList(tempArray);

    console.log("selecettd", tempArray);
  };

  /* DOM NODE */
  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  /* USE-EFFECTS */
  useEffect(() => {
    getRsvpListFunction();
  }, [
    page,
    sizePerPage,
    search,
    eventId,
    status,
    state,
    searchCountry,
    searchCity,
    sector,
    //sortOption,
  ]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Rsvp List | LIBF</title>
      </Helmet>

      {/* Adjusted Width */}
      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Registrations' List
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Registrations for LIBF GCC Calling 2025
            </h2>
          </div>
          {/* SEARCHBAR */}
          <div className="block xl:flex lg:flex items-center gap-2 mt-3 xl:mt-0 lg:mt-0">
            {/* DATE RANGE FILTER */}
            <div className="relative">
              <div ref={domNode}>
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Filter by date range"
                  value={
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? ` ${state?.[0]?.startDate &&
                          moment(state?.[0]?.startDate).format(
                            "DD/MM/YYYY"
                          )} - ${state?.[0]?.endDate &&
                          moment(state?.[0]?.endDate).format("DD/MM/YYYY")}`
                      : "Select Date"
                  }
                  onClick={() => setDatepicker(!datepicker)}
                  className={`w-full xl:w-[25vh] lg:w-[25vh] bg-white outline-none font-ibm-regular text-${
                    state?.[0]?.startDate && state?.[0]?.endDate
                      ? "text-black"
                      : " text-gray-400"
                  } text-sm focus:outline-none border border-greyBorder rounded-md px-5 py-3`}
                />
              </div>
              {datepicker && (
                <div
                  ref={domNode}
                  className="absolute top-12 bottom-0 transition-all duration-300 ease-linear z-50"
                >
                  <DateRangePicker
                    dragSelectionEnabled
                    showPreview
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={true}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    className="shadow-xl font-ibm-regular rounded-xl border border-greyBorder cursor-pointer"
                  />
                </div>
              )}
            </div>

            <Link to={"/push-notification/" + eventId}>
              <Button
                type="button"
                className="w-[20vh] shadow-none hover:shadow-none  hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case text-sm whitespace-nowrap flex-shrink-0"
              >
                Send Push Notification
              </Button>
            </Link>

            <ExcelExport
              data={exportData}
              content={"Export"}
              fileName="bulkExport"
            />
          </div>
        </div>

        {/* FILTERS ROW */}
        <div className="flex items-center justify-end gap-3 mt-5">
          {/* SORT BY PAYMENT DATE */}
          {/* <div className="w-[18vh] mt-2 xl:mt-0 lg:mt-0">
            <Select
              options={sortByPayment}
              className="react-select font-proxima-regular"
              classNamePrefix="dropdown"
              isSearchable
              placeholder="Payment date"
              name="status"
              value={sortOption}
              onChange={(e) => setSortOption(e)}
            />
          </div> */}

          {/* FILTER BY TYPE */}
          <div className="w-full xl:w-[15vh] lg:w-[15vh] flex-shrink-0 mt-2 xl:mt-0 lg:mt-0">
            <Select
              options={filterByStatus}
              className="react-select font-proxima-regular"
              classNamePrefix="dropdown"
              placeholder="Status"
              name="status"
              value={status}
              onChange={(e) => setStatus(e)}
            />
          </div>

          {/* SEARCH BY SECTOR */}
          <div className="w-[25vh] mt-2 xl:mt-0 lg:mt-0">
            <Select
              options={sectorOptions}
              className="react-select font-proxima-regular"
              classNamePrefix="dropdown"
              isSearchable
              placeholder="Sectors"
              name="status"
              value={sector}
              onChange={(e) => setSector(e)}
            />
          </div>

          {/* SEARCHBAR BY CITY */}
          <div className="w-[25vh] mt-2 xl:mt-0 lg:mt-0">
            <Input
              type="text"
              placeholder="Search delegate by city"
              name="search"
              id="search"
              value={searchCity}
              onChange={(e) => setSearchCity(e?.target?.value)}
            />
          </div>

          {/* SEARCHBAR BY COUNTRY */}
          <div className="w-[25vh] mt-2 xl:mt-0 lg:mt-0">
            <Input
              type="text"
              placeholder="Search delegate by country"
              name="search"
              id="search"
              value={searchCountry}
              onChange={(e) => setSearchCountry(e?.target?.value)}
            />
          </div>

          {/* SEARCHBAR BY NAME */}
          <div className="w-[25vh] mt-2 xl:mt-0 lg:mt-0">
            <Input
              type="text"
              placeholder="Search delegate by name"
              name="search"
              id="search"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>

          {/* UPDATE ALL BUTTON */}
          {selectedList?.length > 0 && (
            <Button
              onClick={() => setUpdateAllModal(!updateAllModal)}
              type="button"
              className="w-[30vh] shadow-none hover:shadow-none  hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case text-sm whitespace-nowrap"
            >
              Send Email
            </Button>
          )}
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? rsvpList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            selectableRows
            // paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onSelectedRowsChange={handleChangeSelected}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
            rowsPerPage={sizePerPage}
            paginationRowsPerPageOptions={[50, 100]}
            paginationPerPage={sizePerPage}
            defaultSortFieldId={1}
          />
        </div>

        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete List
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this list?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-ibm-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            {/* <div className="p-5 border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="space-y-4">
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Name:</strong> {selectedContact.firstName}{" "}
                    {selectedContact.lastName}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Email:</strong> {selectedContact.email}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Message:</strong> {selectedContact.message}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Date & Time:</strong>{" "}
                    {moment(selectedContact.createdAt).format(
                      "MMM DD, YYYY HH:MM A"
                    )}
                  </p>
                </div>
              )}
            </div> */}
          </DialogBody>
        </Dialog>

        {/* UPDATE ALL STATUS MODAL */}
        <Dialog
          open={updateAllModal}
          handler={() => setUpdateAllModal(!updateAllModal)}
          size="s"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-md font-ibm-bold text-black tracking-tight">
                Send Email Notification
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setUpdateAllModal(!updateAllModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            {console.log("selected", selectedList)}
            <div className="p-5 border-b border-authBorder">
              <label className="text-sm font-ibm-regular text-black">
                Email Body <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                {/* <textarea
                  rows={3}
                  name="description"
                  id="description"
                  onChange={addEmailNotificationFormik.handleChange}
                  value={addEmailNotificationFormik.values.description}
                  placeholder="Write the email body"
                  className={`block w-full px-5 py-3 rounded-md border ${
                    addEmailNotificationFormik.errors.description &&
                    addEmailNotificationFormik.touched.description
                      ? "border-red-500"
                      : "border-authBorder"
                  } text-sm font-ibm-regular text-black placeholder:text-sm placeholder:text-gray-500 placeholder:font-ibm-regular focus:outline-logoYellow`}
                /> */}

                <Editor
                  toolbar={{ options: ["inline", "link"] }}
                  editorClassName="text-editor h-auto"
                  wrapperClassName={
                    addEmailNotificationFormik.errors.description &&
                    addEmailNotificationFormik.touched.description
                      ? "text-editor-wrapper-error"
                      : "text-editor-wrapper"
                  }
                  onEditorStateChange={(e) => {
                    addEmailNotificationFormik.setFieldValue("description", e);
                  }}
                  editorState={addEmailNotificationFormik.values.description}
                  placeholder="Write the email body here"
                />
              </div>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={() => {
                  addEmailNotificationFormik.handleSubmit();
                }}
                className="text-sm shadow-none hover:shadow-none bg-[#EF4444] hover:bg-opacity-80 px-8 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Send Email
              </Button>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default RsvpList;
