//const SERVER_URL = "http://localhost:8080/admin/";
const SERVER_URL =
  "https://libf-backend-nodejs-development-2mk6mheziq-el.a.run.app/admin/";
const SERVER_URL_USER =
  "https://libf-backend-nodejs-development-2mk6mheziq-el.a.run.app/user";
const IMAGE_URL = "https://storage.googleapis.com/kidsstreet-test/";
const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-IN", {});

  return formatter.format(amount);
};
module.exports = {
  SERVER_URL,
  SERVER_URL_USER,
  IMAGE_URL,
  SET_FORMAT,
};
