export const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2, // or adjust as needed
    maximumFractionDigits: 2, // or adjust as needed
  });
  return formatter.format(amount);
};

export const NUMBER_TO_WORDS = (num) => {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function convertToWords(num) {
    if (num === 0) return "Zero";

    let result = "";
    let thousandIndex = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        result =
          helper(num % 1000) +
          (thousands[thousandIndex] ? " " + thousands[thousandIndex] : "") +
          " " +
          result;
      }
      num = Math.floor(num / 1000);
      thousandIndex++;
    }

    return result.trim() + " Only";
  }

  function helper(num) {
    if (num === 0) return "";

    let str = "";

    if (num >= 100) {
      str += ones[Math.floor(num / 100)] + " Hundred ";
      num %= 100;
    }

    if (num >= 20) {
      str += tens[Math.floor(num / 10)] + " ";
      num %= 10;
    }

    if (num > 0) {
      str += ones[num] + " ";
    }

    return str.trim();
  }

  return convertToWords(num);
};
