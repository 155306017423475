/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/* APIs */
import { getInvoiceDetails } from "service/api";

/* INVOICE COMPONENTS */
import MaharashtraInvoiceComponent from "components/invoices/maharashtra";
import OutsideIndiaInvoiceComponent from "components/invoices/outside-india";
import IndiaInvoiceComponent from "components/invoices/india";

const InvoiceIDPage = () => {
  const params = useLocation();
  let invoiceID = params.pathname.split("/")[2];
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceType, setInvoiceType] = useState(null);
  const [loading, setLoading] = useState(false);

  const getInvoiceDetailsFunction = () => {
    setLoading(true);
    getInvoiceDetails(invoiceID)
      .then((res) => {
        console.log(res.data);
        setInvoiceDetails(res.data?.rsvpDetails);
        setInvoiceId(res.data?.invoiceDetails?.invoiceId);
        setInvoiceType(res.data?.invoiceDetails?.invoiceType);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (invoiceID) {
      getInvoiceDetailsFunction();
    }
  }, [invoiceID]);

  useEffect(() => {
    if (invoiceDetails) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [invoiceDetails]);

  console.log("Invoice details: ", invoiceDetails);

  return loading ? (
    <p className="text-center font-ibm-semibold text-black">Loading</p>
  ) : !invoiceDetails ? (
    <p className="text-center font-ibm-semibold text-black">
      Invoice Not Found
    </p>
  ) : invoiceDetails?.companyDetails?.billingAddress?.country === "India" ||
    invoiceType === "INDIA" ? (
    invoiceDetails?.companyDetails?.billingAddress?.state === "Maharashtra" ? (
      <MaharashtraInvoiceComponent
        invoiceDetails={invoiceDetails}
        invoiceId={invoiceId}
      />
    ) : (
      <IndiaInvoiceComponent
        invoiceDetails={invoiceDetails}
        invoiceId={invoiceId}
      />
    )
  ) : (
    <OutsideIndiaInvoiceComponent
      invoiceDetails={invoiceDetails}
      invoiceId={invoiceId}
    />
  );
};

export default InvoiceIDPage;
