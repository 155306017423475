/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import ReactImageUploading from "react-images-uploading";
import { Link, useParams } from "react-router-dom";

import moment from "moment";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* REACT ICONS */
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import {
  getCompanyList,
  getCompanyDetailsById,
  editCompanyDetailsById,
} from "service/api";

/* Validation Schema */
// Validation Schema For PromoCode Add Form
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const EditCompany = () => {
  // GLOBAL VARIABLES
  const { id } = useParams();
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES

  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  //   const [uploading, setUploading] = useState(false);
  //   const [productFrontImages, setProductFrontImages] = useState([]);

  // Functions
  const GetCompanyFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 100,
      pagination: false,
    };
    getCompanyList(obj)
      .then((response) => {
        if (response?.status) {
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name });
          });
          setCompanyDetails(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetcompanyDetails = () => {
    getCompanyDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setCompanyDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   const handleRemoveImage = (type) => {
  //     if (type === "front") {
  //       setProductFrontImages([]);
  //       // Also handle the removal in productDetails if necessary
  //       setCompanyDetails((prev) => ({ ...prev, image: null }));
  //     }
  //   };
  //   const onFrontImageChange = (imageList) => {
  //     setProductFrontImages(imageList);
  //   };

  const editFormikFunction = useFormik({
    initialValues: {
      name: "",
      //   desc: "",
      //   image: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      // Append form fields
      formData.append("name", values.name);

      // Ensure desc is properly converted
      //

      // H if (values.desc) {
      //     const htmlDesc = draftToHtml(
      //       convertToRaw(values.desc.getCurrentContent())
      //     );
      //     formData.append("desc", htmlDesc);
      //   } else {
      //     formData.append("desc", "");
      //   }andle image field
      //   if (productFrontImages?.[0]?.file) {
      //     formData.append("image", productFrontImages[0].file);
      //   }

      // Submit the form data
      setLoading(true);
      editCompanyDetailsById(id, formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            editFormikFunction.resetForm();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
          GetCompanyFunction();
        });
    },
  });

  useEffect(() => {
    if (companyDetails) {
      editFormikFunction.setFieldValue("name", companyDetails?.name || "");

      // Handle description conversion
      //   const contentBlock = htmlToDraft(companyDetails.desc || "");
      //   if (contentBlock) {
      //     const contentState = ContentState.createFromBlockArray(
      //       contentBlock.contentBlocks
      //     );
      //     const editorState = EditorState.createWithContent(contentState);
      //     editFormikFunction.setFieldValue("desc", editorState);
      //   }

      // Set image
      //   if (companyDetails?.image) {
      //     editFormikFunction.setFieldValue("image", companyDetails.image);
      //   }
    }
  }, [companyDetails]);

  useEffect(() => {
    GetCompanyFunction();
  }, []);

  useEffect(() => {
    if (id) {
      handleGetcompanyDetails();
    }
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Edit Company | LIBF</title>
      </Helmet>

      <div className="container-full-width">
        <form onSubmit={editFormikFunction.handleSubmit}>
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Edit Company
              </h1>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/company">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={loading}
                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
              >
                {loading ? "Please wait..." : "Save"}
              </Button>
            </div>
          </div>

          {/* FORM */}

          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Company Details
                    </h5>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                        {/* NAME */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Company Name{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="name"
                                id="name"
                                onChange={editFormikFunction.handleChange}
                                value={editFormikFunction.values.name || ""}
                                error={
                                  editFormikFunction.errors.name &&
                                  editFormikFunction.touched.name
                                }
                                placeholder="Write the product name"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Event Description */}
                        {/* <div className="col-span-2">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Description{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                editorState={editFormikFunction.values.desc}
                                onEditorStateChange={(editorState) => {
                                  editFormikFunction.setFieldValue(
                                    "desc",
                                    editorState
                                  );
                                }}
                                editorClassName="editor-class"
                              />
                              {editFormikFunction.errors.desc &&
                                editFormikFunction.touched.desc && (
                                  <div className="text-red-500 text-sm">
                                    {editFormikFunction.errors.desc}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* IMAGES AND DISCOUNTS */}
              {/* <div className="bg-white rounded-lg border border-authBorder mt-3">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-sm font-proxima-bold text-black">
                    Product Images
                  </h5>
                  <p className="text-xs font-proxima-regular text-greyText">
                    Attach the Varient images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    value={productFrontImages}
                    onChange={onFrontImageChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        <div className="col-span-3">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-3 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                  {companyDetails?.image && (
                    <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
                      <div className="col-span-1 relative">
                        <img
                          src={companyDetails.image}
                          className="w-30 h-30 object-cover rounded-md"
                          alt="Product"
                        />
                        <div className="absolute top-2 right-2">
                          <Button
                            className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                            onClick={() => handleRemoveImage("front")}
                          >
                            <FiTrash className="w-3 h-3 text-black" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditCompany;
