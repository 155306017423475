/* ASSETS */
import Logo from "assets/images/logo.png";

const IndiaInvoiceComponent = ({ invoiceDetails, invoiceId }) => {
  return (
    <div className=" w-full h-full py-10">
      <div className="container w-full h-full">
        <div className="border border-black w-full h-full">
          {/* ROW 1 */}
          <div className=" flex items-center justify-center">
            <p className="font-source-bold text-xs">Tax Invoice</p>
          </div>

          {/* ROW 2 */}
          <div className="grid grid-cols-6 border-t border-black">
            {/* logo */}
            <div className="col-span-1 border-r border-black flex items-center justify-center">
              <div className="relative w-full h-16 xl:h-[15vh] lg:h-[12vh]">
                <img
                  src={Logo}
                  alt="Shree Lohana Mahaparishad"
                  className="object-contain"
                />
              </div>
            </div>

            {/* Address */}
            <div className="col-span-2 border-r border-black">
              <div className="flex items-center justify-center w-full">
                <p className="font-source-semibold text-xs px-2">
                  Seller (Bill from)
                </p>
              </div>
              <div className="border-t border-black block px-2">
                <p className="font-source-bold text-xs ">
                  LOHANA INTERNATIONAL BUSINESS FORUM
                </p>
                <p className="font-source-semibold text-xs">Address :</p>
                <p className="font-source-regular text-xs">
                  Secondary division, Shree Lohana Kanya Shala
                </p>
                <p className="font-source-regular text-xs">
                  Lohana Mahaparishad Building
                </p>
                <p className="font-source-regular text-xs">
                  Netaji Subhash Chandra Bose Road
                </p>
                <p className="font-source-regular text-xs">
                  Mulund (West), Mumbai - 400080, India
                </p>
                <p className="font-source-regular text-xs">
                  CIN No: U94990MH2023NPL410441
                </p>
                <p className="font-source-regular text-xs">
                  GSTIN: 27AAFCL5516N1Z4
                </p>
                <p className="font-source-regular text-xs">PAN AAFCL5516N</p>
              </div>
            </div>

            {/* Dynamic - Tax Invoice */}
            <div className="col-span-1 border-r border-black">
              <div className="flex items-center justify-center w-full">
                <p className="font-source-semibold text-xs px-2">
                  Tax Invoice no.
                </p>
              </div>
              <div className="border-t border-black block px-2">
                <p className="font-source-bold text-xs flex justify-center">
                  {invoiceId ? invoiceId : "-"}
                </p>
              </div>
            </div>

            {/* Dynamic - Tax Date */}
            <div className="col-span-2 ">
              <div className="flex items-center justify-center w-full">
                <p className="font-source-semibold text-xs px-2">
                  Invoice date:
                </p>
              </div>
              <div className="border-t border-black block px-2">
                <p className="font-source-bold text-xs flex justify-center">
                  {new Date(
                    invoiceDetails?.paymentDetails?.find(
                      (element) => element?.type === "PAYMENT_SUCCESS_WEBHOOK"
                    )?.data?.payment?.payment_time
                  ).toLocaleDateString("en-gb", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
            </div>
          </div>

          {/* ROW 3 */}
          <div className="grid grid-cols-6 border-t border-black">
            {/* Dynamic- Address */}
            <div className="col-span-3 border-r border-black">
              <div className="block px-2">
                <div className="flex items-center gap-2">
                  <p className="font-source-bold text-xs ">Bill To :</p>
                  <p className="font-source-semibold text-xs">
                    {invoiceDetails?.companyDetails?.billingName === ""
                      ? `${invoiceDetails?.personalDetails?.firstName} ${invoiceDetails?.personalDetails?.lastName}`
                      : `${invoiceDetails?.companyDetails?.companyId?.billingName}`}
                  </p>
                </div>
                <div className="flex items-start gap-2">
                  <p className="font-source-bold text-xs flex-none">
                    Address To :
                  </p>
                  {invoiceDetails?.companyDetails?.billingAddress?.address ===
                  "" ? (
                    <p className="font-source-semibold text-xs">
                      {invoiceDetails?.personalDetails?.address?.address},{" "}
                      {invoiceDetails?.personalDetails?.address?.city},{" "}
                      {invoiceDetails?.personalDetails?.address?.state},{" "}
                      {invoiceDetails?.personalDetails?.address?.country} -{" "}
                      {invoiceDetails?.personalDetails?.address?.zipCode}
                    </p>
                  ) : (
                    <p className="font-source-semibold text-xs">
                      {
                        invoiceDetails?.companyDetails?.companyId?.address
                          ?.address
                      }
                      ,{" "}
                      {invoiceDetails?.companyDetails?.companyId?.address?.city}
                      ,{" "}
                      {
                        invoiceDetails?.companyDetails?.companyId?.address
                          ?.state
                      }
                      ,{" "}
                      {
                        invoiceDetails?.companyDetails?.companyId?.address
                          ?.country
                      }{" "}
                      -{" "}
                      {
                        invoiceDetails?.companyDetails?.companyId?.address
                          ?.zipCode
                      }
                    </p>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <p className="font-source-bold text-xs ">GSTIN :</p>
                  <p className="font-source-semibold text-xs">
                    {invoiceDetails?.companyDetails?.companyId?.gstNumber ===
                    undefined
                      ? invoiceDetails?.companyDetails?.gstNumber
                      : invoiceDetails?.companyDetails?.companyId?.gstNumber}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="font-source-bold text-xs ">Pan :</p>
                  <p className="font-source-semibold text-xs">
                    {invoiceDetails?.companyDetails?.companyId?.panNumber ===
                    undefined
                      ? invoiceDetails?.companyDetails?.panNumber
                      : invoiceDetails?.companyDetails?.companyId?.panNumber}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-1 border-r border-black"></div>

            {/* Dynamic - Place of supply */}
            <div className="col-span-2 px-2">
              <div className="flex items-center gap-2">
                <p className="font-source-regular text-xs ">
                  Place of Supply :
                </p>
                <p className="font-source-semibold text-xs">
                  {invoiceDetails?.personalDetails?.address?.state}
                </p>
              </div>{" "}
              <div className="flex items-center gap-2">
                <p className="font-source-regular text-xs ">
                  Tax Payable on Reverse charge :
                </p>
                <p className="font-source-semibold text-xs">No</p>
              </div>
            </div>
          </div>

          {/* ROW 4 */}
          <div className="grid grid-cols-6 border-t border-black">
            {/* Goods/service */}
            <div className="col-span-3 border-r border-black flex items-center">
              <div className="w-16 border-r border-black px-3">
                <p className="font-source-semibold text-xs">SI</p>
                <p className="font-source-semibold text-xs">No. </p>
              </div>
              <div className="w-full  px-3 flex items-center justify-center">
                <p className="font-source-semibold text-xs">
                  Description of Goods / Services
                </p>
              </div>
            </div>

            <div className="col-span-1 border-r border-black">
              <p className="font-source-semibold text-xs flex justify-center">
                Quantity
              </p>
            </div>

            <div className="col-span-2  flex items-center">
              <div className=" border-r border-black px-3">
                <p className="w-20 font-source-semibold text-xs flex items-center">
                  HSN / SAC
                </p>
                <p className="font-source-semibold text-xs">Code </p>
              </div>
              <div className="w-full  px-3 flex items-center justify-center">
                <p className="font-source-semibold text-xs">Amount (in Rs.)</p>
              </div>
            </div>
          </div>

          {/* ROW 5 */}
          <div className="grid grid-cols-6 border-t border-black">
            {/* Goods/service */}
            <div className="col-span-3 border-r border-black flex items-center">
              <div className="w-16 border-r border-black px-3 h-full">
                <p className="font-source-semibold text-xs">1</p>
              </div>
              <div className="w-full  px-3 block">
                <p className="font-source-bold text-xs ">
                  Delegate Fees for LIBF GCC Calling 2025
                </p>
                <div className="flex items-center justify-between">
                  <p className="font-source-semibold text-xs">Add:</p>{" "}
                  <p className="font-source-semibold text-xs">IGST @18%</p>
                </div>
              </div>
            </div>

            {/* Dynamic - Quantity */}
            <div className="col-span-1 border-r border-black">
              <p className="font-source-bold text-base flex justify-end items-center px-3">
                1
              </p>
            </div>

            <div className="col-span-2  flex items-center h-full">
              <div className="w-20  border-r border-black px-3 h-full">
                <p className=" font-source-semibold text-xs flex items-center">
                  998553
                </p>
              </div>
              {/* Dynamic Price */}
              <div className="w-full  px-3 flex flex-col items-end">
                <p className="font-source-bold text-xs">30,000</p>
                <p className="font-source-bold text-xs">5,400</p>
              </div>
            </div>
          </div>

          {/* ROW 6 */}
          <div className="grid grid-cols-6 border-t border-black">
            {/* Goods/service */}
            <div className="col-span-4 border-r border-black flex items-center">
              <div className="w-16 border-r border-black px-3 h-full"></div>
              <div className="w-full  py-0 px-3  ">
                <p className="font-source-semibold text-xs flex justify-end">
                  Total
                </p>
              </div>
            </div>

            {/* Dynamic Total Price */}
            <div className="col-span-2  flex items-center h-full">
              <div className="w-20 border-r border-black px-3 h-full"></div>
              <div className="w-full  px-3 flex flex-col items-end">
                <p className="font-source-bold text-sm ">35,400</p>
              </div>
            </div>
          </div>

          {/* ROW 7 Dynamic- Price*/}
          <div className="px-3 border-t border-black w-full">
            <p className="font-source-bold text-xs py-2">
              Rupees Thirty Five Thousand Four Hundred Only
            </p>
          </div>

          {/* ROW 8 */}
          <div className="px-3 border-t border-black w-full">
            <p className="font-source-regular text-xs py-2">
              We declare that this invoice shows the actual price of the
              goods/services described and that all particulars are true and
              correct.
            </p>
          </div>

          {/* ROW 9 */}
          <div className="px-3 border-t border-black w-full">
            <p className="font-source-regular text-xs py-2">
              Invoice is subject to Mumbai jurisdiction
            </p>
          </div>

          {/* ROW 10 */}
          <div className="grid grid-cols-6 border-t border-black">
            <div className="col-span-3 border-r border-black ">
              <p className="font-source-bold text-xs px-3">
                Bank Details for Remittance{" "}
              </p>
              <div className="border-t border-black py-2 px-3">
                <p className="font-source-regular text-xs">
                  Account Name LOHANA INTERNATIONAL BUSINESS FORUM
                </p>{" "}
                <p className="font-source-regular text-xs">
                  Account No.623505394312
                </p>{" "}
                <p className="font-source-regular text-xs">
                  ICICIC Bank Ltd, Branch: Navsari Bldg. Fort, Mumbai 400 001.
                </p>{" "}
                <p className="font-source-regular text-xs">
                  IFSC Code:: ICIC 0006235
                </p>
              </div>
            </div>
            <div className="col-span-3 ">
              <p className="font-source-bold text-xs px-3">
                for LOHANA INTERNATIONAL BUSINESS FORUM
              </p>
              {/* Dynamic Signature */}
              <div className="flex justify-center items-center h-[12vh]">
                {/* <div className="relative w-full h-16 xl:h-[12vh] lg:h-[12vh]">
                  <Image
                    src={Logo}
                    alt="Shree Lohana Mahaparishad"
                    fill
                    className="object-contain"
                  />
                </div> */}
                <p className="font-source-bold text-xs px-3 ">Sd/-</p>
              </div>
            </div>
          </div>

          {/* ROW 11 */}
          <div className="px-3 border-t border-black w-full">
            <p className="font-source-regular text-xs py-2">E&O Excepted</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IndiaInvoiceComponent;
