/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose, IoEyeSharp } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteContactUsDetailsById,
  getContactUsList,
} from "../../../service/api";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ContactUs = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [contactUsList, setContactUsList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getContactUsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getContactUsListFunction();
  };

  const getContactUsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getContactUsList(params)
      .then((res) => {
        setContactUsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("Error:", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteContactUsDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getContactUsListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.5,
      selector: (row, index) => (
        <span className="text-sm font-ibm-semibold text-black text-opacity-60">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Name",
      grow: 1.5,
      selector: (row) => (
        <div className="p-0">
          <p className="text-base font-ibm-semibold text-black">
            {row.firstName} {row?.lastName}
          </p>
          <a
            href={`mailto:${row.email}`}
            className="block text-sm mt-1 font-ibm-regular text-greyText hover:text-logoYellow transition-all duration-300 ease-in-out"
          >
            {row.email}
          </a>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <a
          href={`tel:${row.phoneNumber}`}
          className="text-sm font-ibm-regular text-black hover:text-logoYellow transition-all duration-300 ease-in-out"
        >
          {row.phoneNumber}
        </a>
      ),
    },
    {
      name: "Message",
      grow: 2,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-greyText leading-relaxed">
          {row.message}{" "}
        </p>
      ),
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <Tooltip content="View" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setSelectedContact(row);
                setViewModal(true);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent border border-authBorder hover:bg-black hover:border-black transition-all duration-300 ease-in-out text-greyText hover:text-white"
            >
              <IoEyeSharp className="w-4 h-4" />
            </Button>
          </Tooltip>
          <Tooltip content="Remove" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent border border-authBorder hover:bg-black hover:border-black transition-all duration-300 ease-in-out text-greyText hover:text-white"
            >
              <FiTrash className="w-4 h-4" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getContactUsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="p-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Contact Us Queries | LIBF</title>
      </Helmet>

      {/* Adjusted Width */}
      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Contact Us
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Manage the queries by your visitors from website.
            </h2>
          </div>
          {/* SEARCHBAR */}
          <div className="w-full xl:w-[30vh] lg:w-[30vh] mt-2 xl:mt-0 lg:mt-0">
            <Input
              type="text"
              placeholder="Search..."
              name="search"
              id="search"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? contactUsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete Contact
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this contact?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-ibm-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            <div className="p-5 border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="space-y-4">
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Name:</strong> {selectedContact.firstName}{" "}
                    {selectedContact.lastName}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Email:</strong> {selectedContact.email}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Message:</strong> {selectedContact.message}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Date & Time:</strong>{" "}
                    {moment(selectedContact.createdAt).format(
                      "MMM DD, YYYY HH:MM A"
                    )}
                  </p>
                </div>
              )}
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default ContactUs;
