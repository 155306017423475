import axios from "axios";
import store from "../redux/store";
import { SERVER_URL, SERVER_URL_USER } from "../config/index";

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});

const requestBackend = axios.create({
  baseURL: SERVER_URL_USER,
  timeout: 1000000,
});

let requests = [];
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getState().user?.token) {
      config.headers.Authorization = `Bearer ${store.getState().user?.token}`;
    }
    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

/* Auth API */
// Login
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Register
export const adminRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Users API */
// Add User Details
export const postUserDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get User List
export const getUsersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get User Details By Id
export const getUsersDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update User Details By Id
export const editUsersDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update User Status By Id
export const editUsersStatusById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove User Details By Id
export const deleteUsers = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Roles API */
// Update Role Details By Id
export const postRoleDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`role/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Role List
export const getRolesList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/role`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Role Details By Id
export const getRoleDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Role Details By Id
export const editRolesDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`role/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Role Status By Id
export const editRolesStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Role Details By Id
export const deleteRoles = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`role/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Events API */
// Update Event Details By Id
export const postEventDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`event/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Event List
export const getEventsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/event`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Event Details By Id
export const getEventDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`event/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Event Details By Id
export const editEventsDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`event/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Event Status By Id
export const editEventsStatusById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`event/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Event Details By Id
export const deleteEvent = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`event/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Contact Us */
// Get Contact Us List
export const getContactUsList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/contact-us", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Contact Us Details By Id
export const deleteContactUsDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* NewsLetter */
// Get News Letter List
export const getNewsLetterList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/news-letter", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete News Letter Details By Id
export const deleteNewsLetterDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/news-letter/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Company API */
// Add Company
export const addCompany = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("company/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Company Details By Id
export const editCompanyDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`company/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Company List
export const getCompanyList = (data) => {
  console.log("%c Line:285 🍿 data", "color:#2eafb0", data);
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/company`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Company Details By Id
export const getCompanyDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`company/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Company Details By Id
export const deleteCompanyById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`company/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Tags API */
// Add Tag
export const addTagDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("tag/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Tag Details By Id
export const editTagDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`tag/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Product Array For Tag  By Id
export const editTagProductArrayById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`tag/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Tag List
export const getTagList = (data) => {
  console.log("%c Line:435 🍒 data", "color:#7f2b82", data);
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/tag`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Tag Details By Id
export const getTagDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`tag/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Tag Details By Id
export const deleteTagById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`tag/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* SPONSOR API */
// add Sponsor Details By Id
export const postSponsorDetails = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`sponsor/`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Sponsor List
export const getSponsorList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/sponsor`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Sponsor Details By Id
export const getSponsorDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`sponsor/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Sponsor Details By Id
export const editSponsorDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`sponsor/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Remove Sponsor Details By Id
export const deleteSponsor = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`sponsor/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* RSVP DETAILS API */
//get rsvp details
export const getRsvpDetails = (eventId, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/rsvp/list/${eventId}`;
      const res = await request.get(url, { params });
      console.log("%c Line:585 🥑 res", "color:#fca650", res);
      resolve(res.data);
    } catch (e) {
      console.log("%c Line:588 🥒 e", "color:#33a5ff", e);
      reject(e);
    }
  });
};

// Delete rsvp Details By Id
export const deleteRsvpDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/rsvp/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//get rsvp details by id
export const getRsvpDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`rsvp/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* RSVP DETAILS API */
//get Sponsor Request details
export const getSponsorRequestDetails = (eventId, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/sponsor-request/list/${eventId}`;
      const res = await request.get(url, { params });
      console.log("%c Line:585 🥑 res", "color:#fca650", res);
      resolve(res.data);
    } catch (e) {
      console.log("%c Line:588 🥒 e", "color:#33a5ff", e);
      reject(e);
    }
  });
};
// Patch Sponsor Request Details  By Id Status Change
export const patchSponsorRequestStatusDetailsById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/sponsor-request/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Delete Sponsor Request Details By Id
export const deleteSponsorRequestDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/sponsor-request/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//get Sponsor Request details by id
export const getSponsorRequestDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`sponsor-request/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Registration Form */
// Get Registration Form List
export const getRegistrationFormList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/registration-form", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Registration Form Details By Id
export const deleteRegistrationFormDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/registration-form/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Help Desk */
// Get Help Desk List
export const getHelpDeskList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/help-desk", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Contact Us Details By Id
export const deleteHelpDeskDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/help-desk/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Email Notification */
// Add Email Notification List
export const postEmailNotification = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/email-notification", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Email Notification List
export const getEmailNotificationList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/email-notification", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Email Notification Details By Id
export const deleteEmailNotificationDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/email-notification/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Push Notification */
// Add Push Notification
export const postPushNotification = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/push-notification", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* GET INVOICE DETAILS API */
export const getInvoiceDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    await requestBackend
      .get(`/invoice/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
