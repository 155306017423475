/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";

import ReactImageUploading from "react-images-uploading";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
/* ICONS */
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import {
  editUsersDetailsById,
  getRolesList,
  getUsersDetailsById,
  postUserDetails,
} from "../../../service/api";
import AddAddressFormComponent from "../../../components/forms/addAddress";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  phoneNumber: yup.string(),
  designation: yup.string(),
  dob: yup.string(),
  address: yup.array(),
  role: yup.object(),
  organization: yup.object(),
  tags: yup.array(),
});

const AddMember = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [tags, setTags] = useState([]);
  const [profileImage, setProfileImage] = useState([]);

  const [addTagOption, setAddTagOption] = useState(false);
  const [newTag, setNewTag] = useState(null);

  const [regionsList, setRegionsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [zonesList, setZonesList] = useState([]);

  const [deleteImage, setDeleteImage] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addAddressDialog, setAddressDialog] = useState(false);

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setProfileImage(imageList);
  };

  const handleAddTags = () => {
    if (newTag) {
      if (tags?.find((el) => el === newTag)) {
        toast.warn("Already Added");
      } else {
        setTags([...tags, newTag]);
      }
      setNewTag("");
    } else {
      setNewTag("");
    }
  };

  const handleRemoveTags = (index) => {
    setTags(tags?.filter((e) => e !== index));
  };

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      designation: "",
      dob: "",
      address: "",
      role: "",
      organization: "",
      tags: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("firstName", values?.firstName);
      formData.append("lastName", values?.lastName);
      if (values?.address) {
        formData.append("address", JSON.stringify(values?.address));
      }
      if (values?.tags) {
        formData.append("tags", JSON.stringify(values?.tags));
      }
      if (values?.email) {
        formData.append("email", values?.email);
      }

      if (values?.phoneNumber) {
        formData.append("phoneNumber", values?.phoneNumber);
      }
      if (values?.designation) {
        formData.append("designation", values?.designation);
      }
      if (values?.dob) {
        formData.append("dob", values?.dob);
      }
      if (values?.role) {
        formData.append("role", values?.role?.value);
      }
      if (values?.organization) {
        formData.append("organization", values?.organization?.value);
      }

      if (profileImage?.[0]?.file) {
        formData.append("image", profileImage?.[0]?.file);
      }

      postUserDetails(formData)
        .then((res) => {
          console.log("res", res);
          if (res?.status) {
            toast.success(res?.message);
            handleGetUserDetails();
            // navigate("/member/edit-member/" + res?.data)
            // dispatch(actions.setToken(res?.data?.token));
            // dispatch(actions.setUser(res?.data?.userData));
            // dispatch(actions.setLoggedIn(true));
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("er", e?.response?.data?.message);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  console.log("error", formik.errors);
  console.log("VALUE", formik.values);

  /* HANDLE FETCH DATA */
  const handleGetRolesListFunction = () => {
    getRolesList({ pagination: false })
      .then((res) => {
        // toast.success(res?.message);
        let tempArray = [];
        res?.data?.forEach((element) => {
          tempArray.push({
            label: element?.name,
            value: element?._id,
          });
        });
        setRolesList(tempArray);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {});
  };
  /* HANDLE DELETE DATA */
  const handleGetUserDetails = () => {
    getUsersDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setUserDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveAddress = (id) => {
    toast.error("Address Removed Successfully");
    let tempArray = [];
    addressList?.forEach((el, index) => {
      console.log("index", index);
      console.log("id", id);
      if (index !== id) {
        tempArray.push(el);
        console.log("el", el);
      }
    });
    console.log("tempArray", tempArray);
    setAddressList(tempArray);
  };

  const handleEditAddress = (data) => {
    setSelectedAddress(data);
    setAddressDialog(!addAddressDialog);
  };

  useEffect(() => {
    handleGetRolesListFunction();
  }, []);

  useEffect(() => {
    formik.setFieldValue("address", addressList);
  }, [addressList]);

  useEffect(() => {
    formik.setFieldValue("tags", tags);
  }, [tags]);

  /* ADDRESS BOX */
  const AddressBox = (props) => {
    return (
      <div className="border border-greyColor shadow p-5 ">
        <div>
          <h5 className="text-base font-proxima-bold text-black">
            {props.addressDetails.addressLine1 &&
              props.addressDetails.addressLine1 + ", "}{" "}
            {props.addressDetails.addressLine2 &&
              props.addressDetails.addressLine2}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.city && props.addressDetails.city + ", "}
            {props.addressDetails.taluka && props.addressDetails.taluka + ", "}
            {props.addressDetails.state && props.addressDetails.state + ", "}
            {props.addressDetails.country && props.addressDetails.country}
            {props.addressDetails.zipCode &&
              " - " + props.addressDetails.zipCode}
          </h5>
        </div>
        <div className="w-10 h-[0.1vh] bg-gray-400 mt-3" />
        <div className="mt-3">
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {props.addressDetails.type}
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {
              regionsList?.find(
                (e) => e?.value === props?.addressDetails?.region
              )?.label
            }
          </h5>
          <h5 className="text-sm font-proxima-regular text-headerGrey">
            {
              zonesList?.find((e) => e?.value === props?.addressDetails?.zone)
                ?.label
            }
          </h5>
        </div>
        <div className="mt-3 flex items-center">
          <p
            onClick={() => {
              handleEditAddress(props);
            }}
            className="cursor-pointer text-headerGrey font-proxima-regular italic text-sm hover:underline mr-5"
          >
            Edit
          </p>
          <p
            onClick={() => {
              handleRemoveAddress(props.id);
            }}
            className="cursor-pointer text-red-500 font-proxima-regular italic text-sm hover:underline"
          >
            Delete
          </p>
        </div>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Add Member | LIBF</title>
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-full-width">
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Member
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Add organization member details for website
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/members">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back
                </Button>
              </Link>
              <Button
                type="submit"
                disabled={loading}
                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
              >
                {loading ? "Please wait..." : "Save "}
              </Button>
            </div>
          </div>

          {/* FORM */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Member Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Add these organization member specific details
                    </p>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        {/* FIRSTNAME */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              First Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={
                                  formik.errors.firstName &&
                                  formik.touched.firstName
                                    ? true
                                    : false
                                }
                                placeholder="Write the region firstName"
                              />
                            </div>
                          </div>
                        </div>

                        {/* LASTNAME */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Last Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={
                                  formik.errors.lastName &&
                                  formik.touched.lastName
                                    ? true
                                    : false
                                }
                                placeholder="Write the region lastName"
                              />
                            </div>
                          </div>
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Email address{" "}
                              <span className="text-red-500">*</span>
                              <div className="mt-1">
                                <InputField
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Email Address"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.errors.email && formik.touched.email
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        {/* PASSWORD */}
                        {/* <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Password <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="password"
                                id="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.errors.password && formik.touched.password ? true : false}
                                placeholder="Write the password"
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* PHONE NUMBER */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Phone Number{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                error={
                                  formik.errors.phoneNumber &&
                                  formik.touched.phoneNumber
                                    ? true
                                    : false
                                }
                                placeholder="Write the region price"
                              />
                            </div>
                          </div>
                        </div>

                        {/* DESIGNATION */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Designation{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="designation"
                                id="designation"
                                value={formik.values.designation}
                                onChange={formik.handleChange}
                                error={
                                  formik.errors.designation &&
                                  formik.touched.designation
                                    ? true
                                    : false
                                }
                                placeholder="Write the region designation"
                              />
                            </div>
                          </div>
                        </div>

                        {/* ROLE */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Role <span className="text-red-500">*</span>
                            </label>
                            <div className="relative mt-1">
                              <Select
                                options={rolesList}
                                placeholder="Select..."
                                className={`${formik.errors.role && "error"}`}
                                classNamePrefix="dropdown"
                                name="role"
                                id="role"
                                value={formik.values.role}
                                onChange={(e) => {
                                  formik.setFieldValue("role", e);
                                }}
                              />

                              {formik.errors.role && formik.touched.role && (
                                <div className="absolute top-3 right-8">
                                  <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                                </div>
                              )}

                              {formik.values.role !== "" && (
                                <div className="absolute top-3 right-8">
                                  <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* ORGANIZATION */}
                        <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Organization{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="relative mt-1">
                              <Select
                                options={organizationsList}
                                placeholder="Select Organization"
                                className={`${formik.errors.organization &&
                                  "error"}`}
                                classNamePrefix="dropdown"
                                name="organization"
                                id="organization"
                                value={formik.values.organization}
                                onChange={(e) => {
                                  formik.setFieldValue("organization", e);
                                }}
                              />

                              {formik.errors.organization &&
                                formik.touched.organization && (
                                  <div className="absolute top-3 right-8">
                                    <AiOutlineExclamationCircle className="w-5 h-5 text-red-500" />
                                  </div>
                                )}

                              {formik.values.organization !== "" && (
                                <div className="absolute top-3 right-8">
                                  <FaRegCircleCheck className="w-5 h-5 text-green-500" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-span-6">
                          <div className="block xl:flex lg:flex items-center justify-between">
                            <div>
                              <label className="text-sm font-proxima-semibold text-black">
                                Tags <span className="text-red-500">*</span>
                              </label>
                            </div>
                            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                              <Button
                                onClick={() => {
                                  setAddTagOption(!addTagOption);
                                }}
                                className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-2 py-1 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out"
                              >
                                Add Tag
                              </Button>
                            </div>
                          </div>
                          {addTagOption && (
                            <div className="grid grid-cols-12 mt-3 mb-3 gap-x-3">
                              <div className=" col-span-12 lg:col-span-3">
                                <div className="form-group">
                                  <InputField
                                    type="text"
                                    placeholder="Assign Tag"
                                    name="tag"
                                    id="tag"
                                    value={newTag}
                                    onChange={(event) => {
                                      setNewTag(event?.target?.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-span-12 lg:col-span-3">
                                <Button
                                  onClick={handleAddTags}
                                  className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out"
                                >
                                  Save details
                                </Button>
                              </div>
                              <div className="col-xl-6 col-sm-12"></div>
                            </div>
                          )}

                          <div className="mt-2 grid grid-cols-5 gap-5">
                            {tags && tags?.length > 0 ? (
                              tags?.map((element, index) => {
                                return (
                                  <div
                                    className="col-span-1 border border-x-authBorder rounded-lg py-1 px-2 relative"
                                    key={index}
                                  >
                                    <p className="text-base font-proxima-semibold text-black">
                                      {element}
                                    </p>
                                    <div className="absolute top-0 right-2">
                                      <Button
                                        className="p-1 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                        onClick={() =>
                                          handleRemoveTags(element)
                                        }
                                      >
                                        <FiTrash className="w-3 h-3 text-black" />
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-base font-proxima-semibold text-black">
                                No Tags Found
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="bg-white rounded-lg border border-authBorder mt-5">
                  <div className="block xl:flex lg:flex items-center justify-between px-5 py-3 border-b border-authBorder">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black">
                        Address
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        Add member Addresses
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                      <Button
                        onClick={() => setAddressDialog(!addAddressDialog)}
                        className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out"
                      >
                        Add Address
                      </Button>
                    </div>
                  </div>

                  {addressList?.length === 0 && (
                    <>
                      <div className="p-5">
                        <p className="text-base font-proxima-regular text-greyText">
                          No Addresses found
                        </p>
                      </div>
                    </>
                  )}

                  {addressList?.length !== 0 &&
                    addressList?.map((address, index) => {
                      return <AddressBox id={index} addressDetails={address} />;
                    })}
                </div>
              </div>

              {/* IMAGES  */}
              <div className="col-span-1">
                {/* IMAGES */}
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Profile Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the profile images for this user
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={profileImage}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* ADD ADDRESS DIALOG */}
      <Dialog
        open={addAddressDialog}
        handler={() => setAddressDialog(!addAddressDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Edit New Address
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setAddressDialog(!addAddressDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <AddAddressFormComponent
              regionsList={regionsList}
              zonesList={zonesList}
              setAddressList={setAddressList}
              address={addressList}
              selectedAddress={selectedAddress}
              setAddressDialog={setAddressDialog}
            />
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default AddMember;
