/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { FiTrash } from "react-icons/fi";
import { IoClose, IoEyeSharp } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteSponsorRequestDetailsById,
  getSponsorRequestDetails,
} from "service/api";
import { Link, useParams } from "react-router-dom";

const SponsorRequestList = () => {
  const { eventId } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(7);

  const [sponsorRequestList, setSponsorRequestList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedSponsorRequest, setSelectedSponsorRequest] = useState(null);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    console.log("e", e);
    setPage(e);
    getSponsorRequestListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getSponsorRequestListFunction();
  };

  const getSponsorRequestListFunction = () => {
    setLoading(true);
    setSponsorRequestList([]);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
    };

    if (search) {
      params.search = search;
    }

    getSponsorRequestDetails(eventId, params)
      .then((res) => {
        console.log("%c Line:74 🌰 res", "color:#93c0a4", res);
        setSponsorRequestList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteSponsorRequestDetailsById(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getSponsorRequestListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.3,
      selector: (row, index) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Company Name",
      grow: 2,
      selector: (row) => (
        <div>
          {console.log("ROW", row)}
          <p className="text-sm font-ibm-semibold text-black">
            {`${row?.companyDetails?.name} `}
          </p>
          {/* <p className="mt-1 text-sm font-ibm-regular text-greyText">
            
          </p> */}
        </div>
      ),
    },
    {
      name: "Event",
      grow: 1.5,
      selector: (row) => (
        <div>
          <p className="text-sm font-ibm-regular text-greyText">
            {row?.companyDetails?.email ? row?.companyDetails?.email : "-"}
          </p>
          <p className="text-sm font-ibm-regular text-greyText">
            {row?.companyDetails?.phoneNumber
              ? row?.companyDetails?.phoneNumber
              : "-"}
          </p>
        </div>
      ),
    },
    {
      name: "Requested By",
      grow: 1,
      selector: (row) => (
        <span className={`text-sm font-ibm-semibold text-black capitalize`}>
          {row?.addedBy?.firstName} {row?.addedBy?.lastName}
        </span>
      ),
    },
    // {
    //   name: "Status",
    //   grow: 0,
    //   selector: (row) => (
    //     <span
    //       className={`text-sm font-ibm-bold text-black capitalize ${
    //         row?.paymentStatus === "success"
    //           ? "text-green-600"
    //           : "text-orange-600"
    //       }`}
    //     >
    //       {row?.paymentStatus}
    //     </span>
    //   ),
    // },
    {
      name: "Date & Time",
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleDateString("en-gb", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {new Date(row.createdAt).toLocaleTimeString("en-gb", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })}
          </p>
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip content="View" className="text-sm font-ibm-semibold">
          <Link to={"/rsvp-details/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          {/* <Tooltip content="Remove" className="text-sm font-ibm-semibold">
            <Button
              onClick={() => {
                setDeleteId(row?._id);
                setDeleteModal(!deleteModal);
              }}
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5"
            >
              <FiTrash className="w-4 h-4 text-greyText" />
            </Button>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getSponsorRequestListFunction();
  }, [page, sizePerPage, search, eventId]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Sponsors | LIBF</title>
      </Helmet>

      {/* Adjusted Width */}
      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Sponsorship Requests
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Glance through the companies interested in sponsorship for the
              LIBF GCC Calling 2025
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center justify-between">
            {/* SEARCHBAR */}
            <div className="w-3/4 xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? sponsorRequestList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
        {/* Delete modal */}
        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete List
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this list?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete
              </Button>
            </div>
          </DialogBody>
        </Dialog>

        {/* View modal */}
        <Dialog
          open={viewModal}
          handler={() => setViewModal(!viewModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            {/* Dialog Header */}
            <div className="border-b border-gray-200 px-5 py-3 flex items-center justify-between bg-white">
              <h5 className="text-lg font-ibm-semibold text-gray-800 tracking-tight">
                View Contact Details
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setViewModal(!viewModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>

            {/* Dialog Body */}
            {/* <div className="p-5 border-b border-gray-200 bg-white">
              {selectedContact && (
                <div className="space-y-4">
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Name:</strong> {selectedContact.firstName}{" "}
                    {selectedContact.lastName}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Email:</strong> {selectedContact.email}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Message:</strong> {selectedContact.message}
                  </p>
                  <p className="text-sm font-ibm-semibold ">
                    <strong>Date & Time:</strong>{" "}
                    {moment(selectedContact.createdAt).format(
                      "MMM DD, YYYY HH:MM A"
                    )}
                  </p>
                </div>
              )}
            </div> */}
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default SponsorRequestList;
